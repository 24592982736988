import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"px-0 blog-page",attrs:{"fluid":""}},[_c(VImg,{attrs:{"max-height":"300","src":"https://d1z76heyx58zfh.cloudfront.net/assets/especializados/especializado-equipo2.png"}}),_c('p',{staticClass:"text-h3 texto-land"},[_vm._v("Nuestro Blog")]),_c(VContainer,{staticClass:"sec-blog",attrs:{"fluid":""}},[_c('p',{staticClass:"rosa-tit text-left"},[_vm._v("Ultimas entradas")]),_c(VCard,{staticClass:"pa-5",attrs:{"max-width":"380"}},[_c(VImg,{attrs:{"contain":"","src":"https://d1z76heyx58zfh.cloudfront.net/assets/home/alianzas-casos-de-exito.jpg"}}),_c(VCardTitle,{staticStyle:{"word-break":"break-word"}},[_vm._v("Addition y Proanalytics unen esfuerzos por la Diversidad e Inclusión")]),_c('div',{staticClass:"text-justify"},[_vm._v(" Diferentes instrumentos nacionales e internacionales han enfocado su atención en la importancia de generar ambientes laborales ... ")]),_c(VCardActions,[_c(VBtn,{staticClass:"my-5",staticStyle:{"text-transform":"none"},attrs:{"outlined":"","color":"#fe6472","to":"/2021/diciembre"},on:{"click":function($event){_vm.reveal = true}}},[_vm._v(" Seguir leyendo ")])],1),_c('p',{staticClass:"text-left"},[_vm._v("2 diciembre 2021")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }